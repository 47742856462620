import React from 'react'
import { Link } from 'gatsby'
import Slider from "react-slick";
import { Component } from 'react';

export default class Pricing extends Component {
    state = { isActive: false };
    handleToggle = () => {
        this.setState({ isActive: !this.state.isActive });
      };
    render() {
        const isActive = this.state.isActive;
        const settings = {
            dots: true,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
            autoplay: false,
            swipeToSlide: true,
            arrows: false,

            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        dots: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        dots: true,
                    }
                },
            ]
        };
        return (

            <section className="pricing-area pt-100 pb-70 bg-f4f7fe">
                <div className="container">
                    <div className="section-title">
                        <h2>Ценовник</h2>
                    </div>
                    {/* <div className="row d-flex justify-content-center text-center"> */}
                    <div className="price-objects-div text-center">
                        {/* <h5 className="align-items-center flex-column d-flex justify-content-center">Месечно</h5>
                        <label className="align-items-center flex-column d-flex justify-content-center switch">
                            <input type="checkbox" onClick={this.handleToggle} defaultChecked />
                            <span className="slider round"></span>
                        </label>
                        <h5 className="align-items-center flex-column d-flex justify-content-center">Годишно</h5> */}
                        <h5 className="price-objects">Месечно</h5>
                        <label className="price-objects switch">
                            <input type="checkbox" onClick={this.handleToggle} defaultChecked />
                            <span className="slider round"></span>
                        </label>
                        <h5 className="price-objects">Годишно</h5>
                    </div>
                        <span className="price-objects-div"></span>
                        <Slider {...settings}>
                            <div className='top-price'>
                                <div className="single-pricing-box">
                                    <div className="pricing-header">
                                        <h3>Стартер</h3>
                                    </div>

                                    <div className="price">
                                        <sup>MKD</sup> 0.00 <br/><sub>месец/корисник </sub>
                                        {/* <sub><br />‎‎‏‏‎ ‎</sub> */}
                                    </div>

                                    <ul className="pricing-features">
                                        <li><i className='bx bxs-badge-check'></i> До 5 корисници</li>
                                        <li className='mobile-only'><i className='bx bxs-badge-check'></i> 200MB Меморија по корисник</li>
                                        <li className='desktop-only'><i className='bx bxs-badge-check'></i> 200MB Меморија по корисник<br /><br /></li>
                                        <li><i className='bx bxs-badge-check'></i> 20 задачи по корисник</li>
                                        <li><i className='bx bxs-x-circle red'></i> Видео состаноци</li>
                                        <li><i className='bx bxs-x-circle red'></i> Офис пакет</li>
                                    </ul>

                                    <div className="btn-box">
                                        <Link to="#" className="default-btn">
                                            Селектирај План
                                            <span></span>
                                        </Link>
                                    </div>
                                    <sub><br /></sub>
                                </div>
                            </div>

                            {/* <div className='top-price'>
                                <div className="single-pricing-box">
                                    <div className="pricing-header">
                                        <h3>Премиум</h3>
                                    </div>

                                    <div className="price">
                                        <sup>MKD</sup> {isActive ? 179 : 149}<br/> <sub>месец/корисник</sub>


                                    </div>

                                    <ul className="pricing-features">
                                        <li><i className='bx bxs-badge-check'></i> 1GB Меморија по корисник</li>
                                        <li><i className='bx bxs-badge-check'></i> 100 задачи по корисник<span className='desktop-only'>‏‏‎&zwnj; &zwnj; &zwnj; &zwnj; &zwnj; &zwnj; &zwnj; &zwnj; ‎</span></li>
                                        <li><i className='bx bxs-badge-check'></i> Видео состаноци*</li>
                                        <li><i className='bx bxs-x-circle red'></i> Тимски извештаи</li>
                                        <li><i className='bx bxs-x-circle red'></i> Офис пакет</li>
                                    </ul>

                                    <div className="btn-box">
                                        <Link to="#" className="default-btn">
                                            Селектирај План
                                            <span></span>
                                        </Link>
                                    </div>
                                <sub className='fakturirano'>{isActive ? "Фактурирано Месечно" : "Фактурирано Годишно"}</sub>
                                </div>
                            </div> */}

                            <div className='top-price'>
                                <div className="single-pricing-box">
                                    <div className="pricing-header">
                                        <h3>Стандард</h3>
                                    </div>

                                    <div className="price">
                                        <sup>MKD</sup> {isActive ? 235 : 198} <br/><sub>месец/корисник</sub>
                                        {/* <sub><br />  {isActive ? "Фактурирано Месечно" : "Фактурирано Годишно"}</sub> */}
                                    </div>

                                    <ul className="pricing-features">
                                        <li><i className='bx bxs-badge-check'></i> 5GB Меморија по корисник</li>
                                        <li><i className='bx bxs-badge-check'></i> Неограничени задачи по корисник</li>
                                        <li><i className='bx bxs-badge-check'></i> Видео состаноци*</li>
                                        <li><i className='bx bxs-badge-check'></i> Тимски извештаи</li>
                                        <li><i className='bx bxs-x-circle red'></i> Офис пакет</li>
                                    </ul>

                                    <div className="btn-box">
                                        <Link to="#" className="default-btn">
                                            Селектирај План
                                            <span></span>
                                        </Link>
                                    </div>
                                    <sub className='fakturirano'>{isActive ? "Фактурирано Месечно" : "Фактурирано Годишно"}</sub>
                                </div>
                            </div>

                            <div className='top-price'>
                                <div className="single-pricing-box">
                                    <div className="pricing-header">
                                        <h3>Бизнис</h3>
                                    </div>

                                    <div className="price">
                                        <sup>MKD</sup> {isActive ? 349 : 290}<br/> <sub>месец/корисник</sub>
                                        {/* <sub><br />  {isActive ? "Фактурирано Месечно" : "Фактурирано Годишно"}</sub> */}
                                    </div>

                                    <ul className="pricing-features">
                                        <li><i className='bx bxs-badge-check'></i> 15GB Меморија по корисник</li>
                                        <li><i className='bx bxs-badge-check'></i> Неограничени задачи по корисник</li>
                                        <li><i className='bx bxs-badge-check'></i> Видео состаноци</li>
                                        <li><i className='bx bxs-badge-check'></i> Тимски извештаи</li>
                                        <li><i className='bx bxs-badge-check'></i> Офис пакет</li>
                                    </ul>

                                    <div className="btn-box">
                                        <Link to="#" className="default-btn">
                                            Селектирај План
                                            <span></span>
                                        </Link>
                                    </div>
                                    <sub className='fakturirano'>{isActive ? "Фактурирано Месечно" : "Фактурирано Годишно"}</sub>
                                </div>
                            </div>
                        </Slider>
                    </div>
            </section>
        );
    }
}
